/// <reference path="../_all.d.ts" />
"use strict";

module BASRaT.Services {
    export class NotificationService {
        //      static $inject = ['toastr'];
        constructor() {

        }

        success = (text?: string) => {
            toastr.success(((text == undefined) ? "Success" : text));
        }
        error = (text?: string) => {
            toastr.error(text, "Error");
        }
        warning = (text?: string) => {
            toastr.warning(text, "Warning");
        }
        info = (text?: string) => {
            toastr.info(text, "Info");
        }
    }
}

(function () {
    angular.module("BASRaT")
        .service('NotificationService', BASRaT.Services.NotificationService);
} ());
